<template>
    <div>
        <mdb-card class="mb-2"
                  style="margin-top:-1rem">
            <mdb-card-body class="p-2">
                <mdb-btn tag="a"
                         size="sm"
                         outline="primary"
                         @click="$router.push('/product/inventory/list')">
                    <i class="fas fa-far fa-file-alt"></i>
                    盤點單紀錄
                </mdb-btn>
                <mdb-btn tag="a"
                         size="sm"
                         outline="default"
                         v-show="show_add_btn"
                         @click="add_inventory">
                    <i class="fa fa-plus"></i>
                    建立盤點單
                </mdb-btn>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2"
                  v-show="show_list">
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-input label="盤點時間"
                                   disabled
                                   v-model="inventory.day" />
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="分店"
                                   disabled
                                   v-model="inventory.store_name" />
                    </mdb-col>
                    <mdb-col md="6">
                        <mdb-input label="盤點人"
                                   disabled
                                   v-model="inventory.admin_list" />
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2"
                  v-show="show_list">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col sm="6"
                             md="4">
                        <mdb-input label="關鍵字查詢"
                                   v-model="search.keyword"></mdb-input>
                    </mdb-col>
                    <mdb-col sm="6"
                             md="4">
                        <mdb-select v-model="productClassList"
                                    @getValue="val=>search.class=val"></mdb-select>
                    </mdb-col>
                </mdb-row>
                <mdb-row class="mb-1 mb-md-0">
                    <mdb-col sm="4">
                        <mdb-input label="顯示無庫存"
                                   id="check_num"
                                   class="mt-3 pl-0"
                                   type="checkbox"
                                   style="color: #757575;"
                                   v-model="search.show_num" />
                    </mdb-col>
                    <mdb-col sm="4">
                        <mdb-input label="顯示已盤"
                                   id="check_inv"
                                   class="mt-3 pl-0"
                                   type="checkbox"
                                   style="color: #757575;"
                                   v-model="search.show_inv" />
                    </mdb-col>
                    <mdb-col sm="4"
                             class="text-right">
                        <mdb-btn color="default"
                                 @click="save_inventory">
                            <i class="fa fa-save"></i>
                            &nbsp;儲存盤點單
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
                <table class="table table-striped rwd-table-dispersion">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>商品名稱</th>
                            <th nowrap>庫存數量</th>
                            <th style="width: 90px;">盤點數量</th>
                            <th nowrap>狀態</th>
                            <th style="width: 130px;"></th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody v-show="product_list.length>0">
                        <tr v-for="(p,pk) in product_list"
                            v-show="p.show || search.show_inv"
                            :key="`p_${pk}`">
                            <td data-title="#"
                                scope="row">{{pk+1}}</td>
                            <td data-title="商品名稱">{{p.prod_name}}<br>編號: {{p.prod_code}}</td>
                            <td data-title="庫存數量">{{p.num_old}}</td>
                            <td data-title="盤點數量"
                                @keypress.enter="save_inventory_item(p)">
                                <mdb-input type="number"
                                           class="my-0"
                                           v-model.number="p.num" />
                            </td>
                            <td data-title="狀態">
                                <div v-show="p.num!=''">
                                    <span class="green-text"
                                          v-show="p.num > p.num_old">盤盈</span>
                                    <span class="red-text"
                                          v-show="p.num_old > p.num">盤損</span>
                                    <span v-show="p.num_old == p.num">正常</span>
                                </div>
                                <div v-show="p.num==''">
                                  &nbsp;
                                </div>
                            </td>
                            <td data-title="">
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="save_inventory_item(p)">
                                    <i class="fa fa-save"></i>
                                    &nbsp;暫存
                                </mdb-btn>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-show="product_list.length==0">
                        <tr>
                            <td colspan="6">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbBtn,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbSelect,
} from "mdbvue";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbSelect,
  },
  data() {
    return {
      show_add_btn: false,
      show_list: false,
      ajaxing: false,
      inventory: {
        day: "",
        store_name: "",
        admin_list: [],
      },
      productList: [],
      productClassList: [{
        value: "",
        text: "所有商品分類",
        selected: true,
      }],
      adminKeyList: {},
      storeKeyList: {},
      productStockKeyList: {},
      inventoryItemKeyList: {},
      search: {
        keyword: "",
        class: "",
        show_num: false,
        show: false,
      },
    };
  },
  computed: {
    product_list() {
      let vue = this,
        output = [];
      vue.productList.map((item) => {
        // 過濾查詢條件
        let chk_num = !vue.search.show_num && item.num_old == 0 && item.show,
            chk_match = true,
            chk_class = true;
        // 關鍵字
        if (vue.search.keyword != "") {
          let scode = vue.change_full_text(vue.search.keyword).split(" ");
          for (let i in scode) {
            if (vue.change_full_text(item.prod_name).indexOf(scode[i]) == -1) {
              chk_match = false;
            }
          }
        }
        // 商品分類
        if (vue.search.class != "") {
          chk_class = item.class.indexOf(vue.search.class) > -1;
        }
        if (!chk_num && chk_match && chk_class) {
          output.push(item);
        }
      });
      return output;
    },
  },
  mounted() {
    console.clear();
    this.get_data();
  },
  updated() {},
  methods: {
    change_full_text(str) {
      var tmp = "";
      for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
          tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
        } else {
          tmp += str[i];
        }
      }
      return tmp.toLowerCase();
    },
    // 取得盤點單資料
    get_data() {
      let vue = this,
        query = [
          {
            name: "admin",
            data: {},
          },
          {
            name: "store",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
          },
          {
            name: "inventory",
            data: {
              status: {
                type: 0,
                value: "0",
              },
            },
          },
          {
            name: "product_class",
            data: {
              active: {
                type: 0,
                value: "1",
              }
            },
          },
        ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          // 使用者資料
          res.data.adminList.forEach((item) => {
            vue.adminKeyList[item.id] = item;
          });
          // 分店資料
          res.data.storeList.forEach((item) => {
            vue.storeKeyList[item.id] = item;
          });
          // 產品分類資料
          res.data.product_classList.forEach((item) => {
            item.value = item.id;
            item.text = item.name;
            vue.productClassList.push(item);
          });
          let inven_check = false;
          // 判斷是否有盤點中的盤點單
          if (res.data.inventoryList.length > 0) {
            // 查詢對應分店
            let inventoryList = res.data.inventoryList.find((item) => {
              return vue.$store.state.id_store == item.id_store;
            });
            if (inventoryList) {
              inven_check = true;
              vue.inventory = inventoryList;
              vue.inventory_create();
            }
          }
          //
          if (!inven_check) {
            vue.$swal
              .fire({
                icon: "info",
                title: "建立新的盤點單?",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
              })
              .then((val) => {
                if (val.value) {
                  // 建立並取出盤點資料
                  vue.add_inventory();
                } else {
                  // 取消
                  vue.show_add_btn = true;
                }
              });
          }
        });
    },
    // 新增盤點單
    add_inventory() {
      let vue = this,
        add_obj = {
          day: vue.$moment().format("YYYY-MM-DD"),
          id_store: vue.$store.state.id_store,
          status: 0,
          id_admin: JSON.stringify([parseInt(vue.$store.state.user.id)]),
        };
      if (!vue.ajaxing) {
        vue.ajaxing = true;
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "product/inventory_save",
              data: {
                type: "add",
                data: JSON.stringify(add_obj),
              },
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              vue.ajaxing = false;
              // 關閉按鈕顯示
              vue.show_add_btn = false;
              //
              vue.inventory = res.data.inventoryList;
              vue.inventory_create();
            }
          });
      }
    },
    // 建立盤點單資料
    inventory_create() {
      let vue = this;
      // 顯示盤點資料
      vue.show_list = true;
      // 分店名稱
      vue.inventory.store_name = vue.storeKeyList[vue.inventory.id_store]
        ? vue.storeKeyList[vue.inventory.id_store].name
        : "";
      // 盤墊人
      vue.inventory.admin_list = [];
      vue.inventory.id_admin_list = [];
      let admin_array = JSON.parse(vue.inventory.id_admin);
      admin_array.forEach((id) => {
        vue.inventory.id_admin_list.push(parseInt(vue.adminKeyList[id].id));
        vue.inventory.admin_list.push(vue.adminKeyList[id].name);
      });
      if (
        vue.inventory.id_admin_list.indexOf(
          parseInt(vue.$store.state.user.id)
        ) == -1
      ) {
        vue.inventory.admin_list.push(vue.$store.state.user.name);
      }
      //
      vue.get_inventory_item_data();
    },
    // 取得盤點項目資料
    get_inventory_item_data() {
      let vue = this,
        query = [
          {
            name: "product",
            data: {
              active: {
                type: 0,
                value: "1",
              },
            },
          },
          {
            name: "product_store_stock",
            data: {
              id_store: {
                type: 0,
                value: vue.inventory.id_store,
              },
              stock: {
                type: 3,
                value: "0",
              },
              ym: {
                type: 0,
                value: vue.$moment().format("YYYY-MM"),
              },
            },
          },
          {
            name: "inventory_item",
            data: {
              id_inventory: {
                type: 0,
                value: vue.inventory.id,
              },
            },
            sort: {
              id: 1,
            },
          },
        ];
      vue.$swal.fire({
        title: "讀取中...",
        html: `<i class="fa fa-spinner fa-pulse fa-4x" style="color:#007bff"></i>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen() {
          vue.$store
            .dispatch("get_form", {
              payload: {
                url: `data/get_data/?query=${JSON.stringify(query)}`,
              },
            })
            .then((res) => {
              // 已盤點產品資料
              res.data.inventory_itemList.forEach((item) => {
                if (!vue.inventoryItemKeyList[item.id_product]) {
                  vue.inventoryItemKeyList[item.id_product] = item;
                }
              });
              // 庫存資料
              res.data.product_store_stockList.forEach((item) => {
                vue.productStockKeyList[item.id_product] = item.stock;
              });
              // 產品資料
              res.data.productList.map((item) => {
                let prod = {};
                prod.id = "";
                prod.id_product = item.id;
                prod.class = JSON.parse(item.class);
                prod.prod_name = item.name;
                prod.prod_code = item.code;
                prod.num = "";
                prod.num_old = parseInt(vue.productStockKeyList[item.id]) || 0;
                prod.show = true;
                //
                if (vue.inventoryItemKeyList[item.id]) {
                  let inven_obj = vue.inventoryItemKeyList[item.id];
                  prod.id = inven_obj.id;
                  prod.num = parseInt(inven_obj.num);
                  prod.num_old = parseInt(inven_obj.num_old);
                  prod.show = false;
                }
                vue.productList.push(prod);
                return item;
              });
              vue.$swal.close();
            });
        },
      });
    },
    // 儲存盤點單產品項目資料
    save_inventory_item(prod) {
      let vue = this,
        add_obj = {
          id_product: prod.id_product,
          id_inventory: vue.inventory.id,
          num: prod.num,
          num_old: prod.num_old,
          id_admin: vue.$store.state.user.id,
        },
        admin_list = vue.inventory.id_admin_list;
      if (!vue.ajaxing) {
        vue.ajaxing = true;
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "product/inventory_item_save",
              data: {
                data: JSON.stringify(add_obj),
                admin_list: JSON.stringify(admin_list),
              },
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              vue.ajaxing = false;
              //
              vue.$swal.fire({
                icon: "success",
                title: "成功！",
                timer: 1000,
                toast: true,
                position: "top-right",
                showConfirmButton: false,
                willClose() {
                  prod.show = false;
                },
              });
            }
          });
      }
    },
    //
    save_inventory() {
      let vue = this,
        add_obj = {
          id: vue.inventory.id,
          id_store: vue.inventory.id_store,
          status: 1,
        };
      if (!vue.ajaxing) {
        vue.ajaxing = true;
        vue.$swal
          .fire({
            icon: "warning",
            title: "儲存後無法再編輯，是否確認儲存?",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
          })
          .then((val) => {
            if (val.value) {
              //
              vue.$store
                .dispatch("post_form", {
                  payload: {
                    url: "product/inventory_save",
                    data: {
                      type: "end",
                      data: JSON.stringify(add_obj),
                    },
                  },
                })
                .then((res) => {
                  if (res.data.status == "ok") {
                    vue.ajaxing = false;
                    vue.$swal
                      .fire({
                        icon: "success",
                        title: "成功！",
                        timer: 1500,
                      })
                      .then(() => {
                        location.reload();
                      });
                  }
                });
            } else {
              // 取消
              vue.ajaxing = false;
            }
          });
      }
    },
  },
};
</script>